<template>
  <div class="container">
    <div class="main-left">
      <van-radio-group v-model="walletBrandName">
        <van-cell-group
          title="Selection payment method"
          v-if="presentmentMode === '02'"
        >
          <van-cell title="" clickable @click="walletBrandName = ''">
            <template #title>
              <div class="flex">
                <img :src="ailpayLogo" />
              </div>
            </template>
            <template #right-icon>
              <div class="flex">
                <div class="logos cell-logos">
                  <img
                    v-for="item in brands"
                    :key="item.value"
                    :src="item.logo"
                  />
                </div>
                <van-radio name="" />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group
          title="Selection payment method"
          v-else-if="presentmentMode === '03'"
        >
          <van-cell
            v-for="item in brands"
            :key="item.paymentMethodType"
            title=""
            clickable
            @click="walletBrandName = item.paymentMethodType"
          >
            <template #title>
              <div class="flex">
                <div class="logos cell-logos">
                  <img
                    v-for="logo in item.logos"
                    :key="logo.logoName"
                    :src="logo.logoUrl"
                  />
                </div>
              </div>
            </template>
            <template #right-icon>
              <div class="flex">
                <span>{{ promos }}</span>
                <van-radio :name="item.paymentMethodType" style="width: 35px" />
              </div>
            </template>
          </van-cell>
          <!-- <van-cell
            title=""
            clickable
            @click="walletBrandName = wechatPay.value"
          >
            <template #title>
              <div class="flex">
                <img :src="wechatPay.logo" alt="" />
                <span>{{ wechatPay.walletBrandName }}</span>
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="wechatPay.value" />
            </template>
          </van-cell> -->
        </van-cell-group>
        <van-cell-group title="Selection payment method" v-else>
          <van-cell
            v-for="item in brands"
            :key="item.value"
            title=""
            clickable
            @click="walletBrandName = item.value"
          >
            <template #title>
              <div class="flex">
                <img :src="item.logo" alt="" />
                <span>{{ item.walletBrandName }}</span>
              </div>
            </template>
            <template #right-icon>
              <img
                v-if="item.value !== 'wechat'"
                :src="ailpayLogo"
                class="ailpay-logo"
              />
              <van-radio :name="item.value" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="main-right">
      <van-card
        num="1"
        origin-price="120"
        price="90"
        desc="Goods descript"
        title="Goods Title"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      />
      <van-cell-group style="margin: 20px 0">
        <van-cell title="Goods Price" value="JPY 90" />
        <van-cell title="Shipping Price" value="JPY 5" />
        <van-cell title="Tax" value="JPY 5" />
        <van-cell title="Total" :value="`JPY ${orderPrice}`" />
        <template v-if="isCVS">
          <van-field
            v-model="form.name"
            label="Name"
            maxlength="20"
            input-align="right"
            placeholder="Please iput name"
          />
          <van-field
            v-model="form.surname"
            label="Surname"
            maxlength="20"
            input-align="right"
            placeholder="Please iput surname"
          />
          <van-field
            v-model="form.tel"
            label="Tel"
            type="tel"
            maxlength="11"
            input-align="right"
            placeholder="Please iput phone"
          />
          <van-field
            v-model="form.mail"
            label="Mail"
            maxlength="128"
            type="mail"
            input-align="right"
            placeholder="Please iput E-mail"
          />
        </template>
      </van-cell-group>
      <van-button type="primary" block :loading="loading" @click="handlePay">
        Continue to pay
      </van-button>
    </div>

    <div v-if="visible" class="modal">
      <div class="modal-body">
        <a class="close" @click="handleClose"><van-icon name="cross" /></a>
        <div class="qr-left">
          <p class="amount">
            JPY <span class="blue">{{ orderPrice }}</span>
          </p>
          <vue-qr
            :logoSrc="walletBrandName === 'wechat' ? '' : logo"
            :text="codeUrl"
            :size="240"
          />
          <div class="time">
            QR code will expire in
            <van-icon name="underway-o" />
            <van-count-down :time="time" format="mm min ss s" />
          </div>
        </div>

        <div class="qr-right">
          <h4>You can scan and pay using:</h4>
          <div class="flex">
            <div v-if="walletBrandName === 'wechat'" class="logos">
              <img :src="wechatPay.logo" />
            </div>
            <div v-else-if="presentmentMode === '02'" class="logos">
              <img v-for="item in brands" :key="item.value" :src="item.logo" />
            </div>
            <div v-else class="logos">
              <img :src="brand.logo" />
            </div>
          </div>
          <small
            >(Download and use any of these supported wallet apps to pay)</small
          >

          <p>How to use:</p>
          <ul>
            <li>1. Open your wallet app on your device</li>
            <li>2. Tap on the “Scan” feature</li>
            <li>3. Scan QR code on the left to complete payment</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import store from '@/store'
import {
  openCreate,
  openConsult,
  openGet,
  getConfig,
  openCreateV2,
} from '@/apis'
import logo from '@/assets/logo.svg'
import wechatImg from '@/assets/wechatpay.png'
import { CVS, TELBILL, osType } from '@/utils'

export default {
  name: 'Index',
  components: { VueQr },
  data() {
    return {
      logo,
      visible: false,
      loading: false,
      walletBrandName: '',
      presentmentMode: '',
      ailpayLogo: '',
      orderPrice: 100,
      brands: [],
      time: 10 * 60 * 1000,
      codeUrl: '',
      timer: null,
      form: {},
      wechatPay: {
        walletBrandName: 'WeChat Pay',
        value: 'wechat',
        logo: wechatImg,
      },
      promos: '',
    }
  },
  computed: {
    brand() {
      return this.brands.find((item) => {
        return (
          this.walletBrandName === item.value ||
          this.walletBrandName === item.paymentMethodType
        )
      })
    },
    isCVS() {
      const { brandType } = this.$route.query
      return CVS.includes(brandType)
    },
    isTELBILL() {
      const { brandType } = this.$route.query
      return TELBILL.includes(brandType)
    },
  },
  created() {
    this.presentmentMode = this.$route.query.presentmentMode
    this.initForm()
    this.getOpenConsult()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    initForm() {
      const { walletBrandName, orderPrice } = this
      const { presentmentMode, brandType } = this.$route.query
      const isBrandCashier = Number(this.$route.query.isBrandCashier || 1)
      const clientOrderNo = new Date().getTime()
      this.form = {
        brandName: '',
        brandType: brandType || (walletBrandName === 'wechat' ? '02' : '91'),
        cancelUrl:
          brandType === '12' ||
          brandType === '92' ||
          this.isCVS ||
          this.isTELBILL
            ? 'https://demo.ctshop.colossaltech.cn/#/fail?status=cancel'
            : undefined,
        clientOrderNo,
        errorUrl:
          brandType === '12' ||
          brandType === '92' ||
          this.isCVS ||
          this.isTELBILL
            ? 'https://demo.ctshop.colossaltech.cn/#/fail?status=error'
            : undefined,
        isBrandCashier,
        isSync: 1,
        mail: '',
        name: '',
        orderPrice,
        orderSubject: 'Test Order',
        osType: osType,
        payType: '02',
        presentmentMode,
        redirectUrl: 'https://demo.ctshop.colossaltech.cn/#/dummytools/result',
        surname: '',
        tel: '',
        terminalType: '02',
        token: '',
        tokenKey: '',
        walletBrandName: walletBrandName === 'wechat' ? '' : walletBrandName,
      }
    },
    getOpenConsult() {
      const orderPrice = this.orderPrice
      const { presentmentMode } = this.$route.query
      openConsult({
        orderPrice,
        presentmentMode,
        terminalType: '02',
      }).then((res) => {
        if (presentmentMode === '01' || presentmentMode === '02') {
          this.ailpayLogo = res.paymentOptions[0].logo.logoUrl
          this.brands =
            res.paymentOptions[0].paymentOptionDetail.connectWallet.supportWallets.map(
              (item) => {
                const brand = {
                  walletBrandName: item.walletBrandName,
                  value: item.walletName,
                  logo: item.walletLogo.logoUrl,
                }
                return brand
              }
            )
        } else if (presentmentMode === '03') {
          this.promos = res.promos
          this.brands = res.paymentOptions.filter(
            (item) => item.enabled === 'true'
          )
        }

        // if (presentmentMode !== '02') {
        //   this.brands.push(this.wechatPay)
        // }
      })
    },
    handlePay() {
      this.initForm()
      if (
        (this.presentmentMode === '01' || this.presentmentMode === '03') &&
        !this.walletBrandName
      ) {
        this.$toast.fail('Please select a payment method')
        return
      }

      if (this.form.brandType === '92' && this.form.isBrandCashier === 0) {
        this.loading = true
        store.dispatch('setForm', this.form)
        this.getConfig()
      } else {
        this.getConsultPayment()
      }
    },
    async getConfig() {
      const timeStamp = new Date().getTime()
      const { merchantId, serviceId } = await getConfig({ timeStamp })
      this.loading = false
      this.$router.push({
        path: '/credit',
        query: {
          merchantId,
          serviceId,
        },
      })
    },
    getConsultPayment() {
      if (this.presentmentMode === '03') {
        this.form.brandName = this.brand.brandName
      }
      this.form.walletBrandName = this.walletBrandName
      this.loading = true
      if (
        this.isTELBILL ||
        this.isCVS ||
        (this.form.brandType === '92' && this.form.isBrandCashier === 0) ||
        this.form.brandType === '93'
      ) {
        openCreateV2(this.form)
          .then((res) => {
            this.afterCreate(res)
          })
          .catch((_) => {
            this.loading = false
          })
      } else {
        openCreate(this.form)
          .then((res) => {
            this.afterCreate(res)
          })
          .catch((_) => {
            this.loading = false
          })
      }
    },
    afterCreate(res) {
      const { walletBrandName } = this
      const { isBrandCashier, brandType } = this.form
      localStorage.setItem('clientOrderNo', res.clientOrderNo)
      // if (
      //   brandType === '12' ||
      //   brandType === '92' ||
      //   this.isCVS ||
      //   this.isTELBILL
      // ) {
      //   const form = document.createElement('div')
      //   form.style.display = 'none'
      //   document.body.append(form)
      //   form.innerHTML = decodeURIComponent(res.codeUrl)
      //   form.querySelector('form').submit()
      // } else if (walletBrandName === 'wechat' || isBrandCashier === 0) {
      //   this.visible = true
      //   this.codeUrl = res.codeUrl
      //   this.timer = setInterval(this.getResult, 3000)
      // } else {
      //   location.href = res.codeUrl
      // }
      location.href = res.codeUrl
      this.loading = false
    },
    getResult() {
      const clientOrderNo = localStorage.getItem('clientOrderNo') || ''
      openGet({ clientOrderNo }).then((data) => {
        if (data.orderStatus !== 0) {
          this.$router.push('/result')
          clearInterval(this.timer)
        }
      })
    },
    handleClose() {
      clearInterval(this.timer)
      this.visible = false
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: space-between;
  width: 1080px;
  margin: 0 auto;
}
.van-cell--clickable {
  align-items: center;
}
.main-left {
  margin-top: 60px;
  padding: 40px;
  width: 740px;
  min-height: 447px;
  background: #fff;
  box-sizing: border-box;
}
.main-right {
  margin-top: 60px;
  padding: 20px;
  width: 320px;
  background: #fff;
  box-sizing: border-box;
}
.flex {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.van-cell-group img,
.logos img {
  margin-right: 10px;
  height: 40px;
  &.ailpay-logo {
    height: 30px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.cell-logos {
  width: 490px;
}
.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 60px;
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }

  .qr-left {
    text-align: center;
    .amount {
      text-align: center;
      margin: 0;
      font-weight: 600;
      font-size: 24px;
    }
    .blue {
      color: #1677ff;
    }
  }
  .qr-right {
    small {
      color: #999;
    }
    h4 {
      font-size: 20px;
    }
    li {
      color: #666;
      font-size: 14px;
      line-height: 2;
    }
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    .van-icon {
      margin-left: 5px;
      color: #f00;
    }
    .van-count-down {
      margin-left: 5px;
      color: #f00;
    }
  }
}
</style>
